import React from 'react';
import { BrowserRouter , Route, Routes , Navigate} from 'react-router-dom';
import { Home } from '../components/Home/Home';
import { ImporterDocument } from '../components/ImporterDocument/ImporterDocument';
import { ImporterDocuments } from '../components/ImporterDocuments/ImporterDocuments';
export const AppRouter = () => {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route exact path="/" element={<Navigate to="/w1003"/>} />
					<Route exact path="/:lote" element={<Home/>} />
					<Route exact path="/importer-documents/:lote" element={<ImporterDocuments/>}></Route>
					<Route exact path="/importer/:doc/:lote" element={<ImporterDocument/>}></Route>
				</Routes>
			</BrowserRouter>
		</>
	);
};
