import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, ModalBody } from 'reactstrap'
import './ImporterDocuments.css'
import logo from '../../assets/png/logo.png'
import { IoIosArrowBack } from 'react-icons/io'
import { Link, useParams } from 'react-router-dom'
import rightArrow from '../../assets/svg/rightArrow.svg'
import api from '../../axios'

const antibiotics = [
    {
        title: 'SULFONAMIDES'
    },
    {
        title: 'TETRACYCLINES'
    },
    {
        title: 'TYLOSIN'
    },
    {
        title: 'AMITRAZ'
    },
    {
        title: 'CHLORAMPHENICOL'
    },
    {
        title: 'FLORFENICOL'
    },
    {
        title: 'POF'
    }
]
// const honey_foreign_oligosacharides = [
// 	{
// 		title: 'PSICOSE',
// 	},
// ];
const residues = [
    // {
    // 	title: 'POF',
    // },
    // {
    // 	title: 'PA',
    // },
    {
        title: 'GLYPHOSATE'
    }
]
// const genetically_modified_organism = [
// 	{
// 		title: 'GMO',
// 	},
// ];
const chemical_physical_parameters = [
    // {
    // 	title: 'Conductivity',
    // },
    // {
    // 	title: 'FG',
    // },

    {
        title: 'HUMIDITY-SUCROSE-FRUCTOSE- GLUCOSE-TOTAL SUGAR'
    }
]
const product_certification = [
    {
        title: 'COI'
    },
    {
        title: 'COI LOT'
    }
]
const documents = [
    { title: 'ANTIBIOTICS', list: antibiotics },
    // { title: 'HONEY FOREIGN OLIGOSACHARIDES', list: honey_foreign_oligosacharides },
    { title: 'RESIDUES', list: residues },
    // { title: 'GENETICALLY MODIFIED ORGANISM', list: genetically_modified_organism },
    { title: 'CHEMICAL - PHYICAL PARAMETERS', list: chemical_physical_parameters }
]
export const ImporterDocuments = () => {
    const [lotInfo, setLotInfo] = useState({})
    const [fatherCategories, setFatherCategories] = useState([])
    const [childCategories, setChildCategories] = useState([])
    const [documents, setDocuments] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { lote } = useParams()

    const getLotInfo = async () => {
        const resp = await api.get(`lots/path/${lote}`)
        console.log(resp)
        setLotInfo(resp.data)
        const fatherCategoriesResp = await api.get(`categories/lot/${resp.data.id}`)
        setFatherCategories(
            fatherCategoriesResp?.data?.data?.filter(category => {
                return category.parent === null
            })
        )
        setChildCategories(
            fatherCategoriesResp?.data?.data?.filter(category => {
                return category.parent !== null
            })
        )
        const documents = await api.get(`documents/lot/${resp.data.id}`)
        console.log(documents)
        setDocuments(documents?.data)
    }

    useEffect(() => {
        getLotInfo()
    }, [])
    return (
        <>
            <Row className="page-title-importer">
                <Col xs={11} className="page-title-importer-col">
                    <Link to={`/${lote}`}>
                        <IoIosArrowBack style={{ color: 'black' }} />
                    </Link>
                    <div className="page-title-importer-text">
                        <span>Importer Documents</span>
                    </div>
                </Col>
            </Row>
            <div className="importer-page">
                <div className="lot-details-box">
                    <Row className="lot-details">
                        <Col xs={12} sm={12} md={12} className="lot-details-header">
                            <span> {lote}</span>
                        </Col>
                        <Col xs={11} sm={11} md={11} className="beekeeper-details">
                            <div className="beekeeper-details-title">
                                <span>EXPORTER</span>
                            </div>
                            <div className="beekeeper-details-name">
                                <span> {lotInfo?.exporter} </span>
                            </div>
                            <div className="separator"></div>
                        </Col>
                        <Col xs={11} sm={11} md={11} className="beekeeper-details">
                            <div className="beekeeper-details-title">
                                <span>IMPORTER</span>
                            </div>
                            <div className="beekeeper-details-name origin-box">
                                <span>{lotInfo?.importer}</span>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="documents-wrapper">
                    {fatherCategories &&
                        fatherCategories.length > 0 &&
                        fatherCategories.map(categoryFather => {
                            return (
                                <Row className="documents-wrapper-box">
                                    <div className="documents-wrapper-box-title">
                                        <div>
                                            <span>{categoryFather.name}</span>
                                        </div>
                                    </div>
                                    {documents &&
                                        documents.length > 0 &&
                                        documents.map(doc => {
                                            if (doc?.category?.id === categoryFather?.id) {
                                                return (
                                                    <Col
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        className="document-item-box"
                                                    >
                                                        <Link to={`/importer/${doc?.id}/${lote}`}>
                                                            <Row className="document-item">
                                                                <Col
                                                                    className="doc-name"
                                                                    xs={8}
                                                                    sm={8}
                                                                    md={10}
                                                                >
                                                                    <span> {doc.name}</span>
                                                                </Col>
                                                                <Col xs={4} sm={4} md={2}>
                                                                    <img src={rightArrow} alt="" />
                                                                </Col>
                                                            </Row>
                                                        </Link>
                                                    </Col>
                                                )
                                            }
                                        })}
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        {childCategories &&
                                            childCategories.length > 0 &&
                                            childCategories.map((item, index) => {
                                                if (item?.parent?.id === categoryFather?.id) {
                                                    return (
                                                        <Row>
                                                            <Col
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                className="beekeeper-details-title category-title"
                                                            >
                                                                <span>{item.name}</span>
                                                            </Col>
                                                            <Col
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                className="document-item-box"
                                                                key={index}
                                                            >
                                                                {documents &&
                                                                    documents.length > 0 &&
                                                                    documents.map(doc => {
                                                                        if (
                                                                            doc?.category?.id ===
                                                                            item?.id
                                                                        ) {
                                                                            return (
                                                                                <Link
                                                                                    to={`/importer/${doc?.id}/${lote}`}
                                                                                >
                                                                                    <Row className="document-item">
                                                                                        <Col
                                                                                            className="doc-name"
                                                                                            xs={8}
                                                                                            sm={8}
                                                                                            md={10}
                                                                                        >
                                                                                            <span>
                                                                                                {' '}
                                                                                                {
                                                                                                    doc.name
                                                                                                }
                                                                                            </span>
                                                                                        </Col>
                                                                                        <Col
                                                                                            xs={4}
                                                                                            sm={4}
                                                                                            md={2}
                                                                                        >
                                                                                            <img
                                                                                                src={
                                                                                                    rightArrow
                                                                                                }
                                                                                                alt=""
                                                                                            />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    })}
                                                                <div className="separator"></div>
                                                            </Col>
                                                        </Row>
                                                    )
                                                }
                                            })}
                                    </Col>
                                </Row>
                            )
                        })}
                </div>
                {/* <div className="documents-wrapper">
					<Row className="documents-wrapper-box">
						<div className="documents-wrapper-box-title">
							<div>
								<span>PRODUCT CERTIFICATION</span>
							</div>
						</div>
						<Row>
							{product_certification.map((item, index) => {
								return (
									<Col xs={12} sm={6} md={6} lg={6} className="document-item-box" key={index}>
										<Link to={`/importer/${item.title}/${lote}`}>
											<Row className="document-item">
												<Col className="doc-name" xs={8} sm={8} md={8}>
													<span> {item.title}</span>
												</Col>
												<Col xs={4} sm={4} md={4}>
													<img src={rightArrow} alt="" />
												</Col>
											</Row>
										</Link>
										<div className="separator"></div>
									</Col>
								);
							})}
						</Row>
					</Row>
				</div> */}

                <Row className="contact-us">
                    <a target="_blank" rel="noreferrer" href="https://hunitro.com/contact/">
                        CONTACT US
                    </a>
                </Row>
                <Row style={{ margin: '1rem' }}>
                    <div>
                        <img style={{ height: '50px' }} src={logo} alt="" />
                    </div>
                </Row>
            </div>
        </>
    )
}
