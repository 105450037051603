import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, ModalBody } from 'reactstrap';
import { useParams } from 'react-router-dom';
import './ImporterDocument.css';
import logo from '../../assets/png/logo.png';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';

import pdfThumb from '../../assets/svg/pdfIcon.svg';
import blockChainLock from '../../assets/svg/blockChainText.svg';
import BLOCKCHAINPROOF from '../../assets/svg/BLOCKCHAINPROOF.svg';
import donwloadIcon from '../../assets/png/downloadIcon.png';
import verifyIcon from '../../assets/png/verifyIcon.png';

import amitraz_pdf from '../../assets/pdf/AMITRAZ.pdf';
import amitraz_ots from '../../assets/ots/AMITRAZ.pdf.ots';
import cloramphenicol_pdf from '../../assets/pdf/CLORAMPHENICOL.pdf';
import cloramphenicol_ots from '../../assets/ots/CLORAMPHENICOL.pdf.ots';
import florphenicol_pdf from '../../assets/pdf/FLORPHENICOL.pdf';
import florphenicol_ots from '../../assets/ots/FLORPHENICOL.pdf.ots';
import FLUVALINATE_pdf from '../../assets/pdf/FLUVALINATE.pdf';
import FLUVALINATE_ots from '../../assets/ots/FLUVALINATE.pdf.ots';
import GLYPHOSATE_pdf from '../../assets/pdf/GLYPHOSATE.pdf';
import GLYPHOSATE_ots from '../../assets/ots/GLYPHOSATE.pdf.ots';
import RAPELAS_REPORT_pdf from '../../assets/pdf/RAPELAS REPORT W 1036.pdf';
import RAPELAS_REPORT_ots from '../../assets/ots/RAPELAS REPORT W 1036.pdf.ots';
import SULFONAMIDES_pdf from '../../assets/pdf/SULFONAMIDES.pdf';
import SULFONAMIDES_ots from '../../assets/ots/SULFONAMIDES.pdf.ots';
import TETRACYCLINES_pdf from '../../assets/pdf/TETRACYCLINES.pdf';
import TETRACYCLINES_ots from '../../assets/ots/TETRACYCLINES.pdf.ots';
import TYLOSIN_pdf from '../../assets/pdf/TYLOSIN_1036.pdf';
import TYLOSIN_ots from '../../assets/ots/TYLOSIN_1036.pdf.ots';

import amitraz_pdf_1037 from '../../assets/pdf/AMITRAZW1037.pdf';
import amitraz_ots_1037 from '../../assets/ots/AMITRAZW1037.pdf.ots';
import cloramphenicol_pdf_1037 from '../../assets/pdf/CLORAMPHENICOLW1037.pdf';
import cloramphenicol_ots_1037 from '../../assets/ots/CLORAMPHENICOLW1037.pdf.ots';
import florphenicol_pdf_1037 from '../../assets/pdf/FLORPHENICOLW1037.pdf';
import florphenicol_ots_1037 from '../../assets/ots/FLORPHENICOLW1037.pdf.ots';
import FLUVALINATE_pdf_1037 from '../../assets/pdf/FLUVALINATEW1037.pdf';
import FLUVALINATE_ots_1037 from '../../assets/ots/FLUVALINATEW1037.pdf.ots';
import GLYPHOSATE_ots_1037 from '../../assets/ots/GLYPHOSATEW1037.pdf.ots';
import GLYPHOSATE_pdf_1037 from '../../assets/pdf/GLYPHOSATEW1037.pdf';
import RAPELAS_REPORT_pdf_1037 from '../../assets/pdf/RAPELAS REPORT W 1037.pdf';
import RAPELAS_REPORT_ots_1037 from '../../assets/ots/RAPELAS REPORT W 1037.pdf.ots';
import SULFONAMIDES_pdf_1037 from '../../assets/pdf/SULFONAMIDESW1037.pdf';
import SULFONAMIDES_ots_1037 from '../../assets/ots/SULFONAMIDESW1037.pdf.ots';
import TETRACYCLINES_pdf_1037 from '../../assets/pdf/TETRACYCLINESW1037.pdf';
import TETRACYCLINES_ots_1037 from '../../assets/ots/TETRACYCLINESW1037.pdf.ots';
import TYLOSIN_pdf_1037 from '../../assets/pdf/TYLOSINW1037.pdf';
import TYLOSIN_ots_1037 from '../../assets/ots/TYLOSINW1037.pdf.ots';
import api from '../../axios';

// const pdfs_1036 = [
// 	{
// 		pdf: TYLOSIN_pdf,
// 		ots: TYLOSIN_ots,
// 		title: 'TYLOSIN',
// 	},
// 	{
// 		pdf: TETRACYCLINES_pdf,
// 		ots: TETRACYCLINES_ots,
// 		title: 'TETRACYCLINES',
// 	},
// 	{
// 		pdf: SULFONAMIDES_pdf,
// 		ots: SULFONAMIDES_ots,
// 		title: 'SULFONAMIDES',
// 	},
// 	{
// 		pdf: amitraz_pdf,
// 		ots: amitraz_ots,
// 		title: 'AMITRAZ',
// 	},
// 	{
// 		pdf: cloramphenicol_pdf,
// 		ots: cloramphenicol_ots,
// 		title: 'CHLORAMPHENICOL',
// 	},
// 	{
// 		pdf: florphenicol_pdf,
// 		ots: florphenicol_ots,
// 		title: 'FLORFENICOL',
// 	},
// 	{
// 		pdf: FLUVALINATE_pdf,
// 		ots: FLUVALINATE_ots,
// 		title: 'POF',
// 	},
// 	{
// 		pdf: GLYPHOSATE_pdf,
// 		ots: GLYPHOSATE_ots,
// 		title: 'GLYPHOSATE',
// 	},
// 	{
// 		pdf: RAPELAS_REPORT_pdf,
// 		ots: RAPELAS_REPORT_ots,
// 		title: 'HUMIDITY-SUCROSE-FRUCTOSE- GLUCOSE-TOTAL SUGAR',
// 	},
// ];

// const pdfs_1037 = [
// 	{
// 		pdf: TYLOSIN_pdf_1037,
// 		ots: TYLOSIN_ots_1037,
// 		title: 'TYLOSIN',
// 	},
// 	{
// 		pdf: TETRACYCLINES_pdf_1037,
// 		ots: TETRACYCLINES_ots_1037,
// 		title: 'TETRACYCLINES',
// 	},
// 	{
// 		pdf: SULFONAMIDES_pdf_1037,
// 		ots: SULFONAMIDES_ots_1037,
// 		title: 'SULFONAMIDES',
// 	},
// 	{
// 		pdf: amitraz_pdf_1037,
// 		ots: amitraz_ots_1037,
// 		title: 'AMITRAZ',
// 	},
// 	{
// 		pdf: cloramphenicol_pdf_1037,
// 		ots: cloramphenicol_ots_1037,
// 		title: 'CHLORAMPHENICOL',
// 	},
// 	{
// 		pdf: florphenicol_pdf_1037,
// 		ots: florphenicol_ots_1037,
// 		title: 'FLORFENICOL',
// 	},
// 	{
// 		pdf: FLUVALINATE_pdf_1037,
// 		ots: FLUVALINATE_ots_1037,
// 		title: 'POF',
// 	},
// 	{
// 		pdf: GLYPHOSATE_pdf_1037,
// 		ots: GLYPHOSATE_ots_1037,
// 		title: 'GLYPHOSATE',
// 	},
// 	{
// 		pdf: RAPELAS_REPORT_pdf_1037,
// 		ots: RAPELAS_REPORT_ots_1037,
// 		title: 'HUMIDITY-SUCROSE-FRUCTOSE- GLUCOSE-TOTAL SUGAR',
// 	},
// ];

export const ImporterDocument = () => {
	const [pdf, setPdf] = useState('');
	const [document, setDocument] = useState('');
	const [modalPdf, setModalPdf] = useState(false);
	const { doc, lote } = useParams();

	const toggleModalPdf = (pdf1) => {
		setPdf(pdf1);
		setModalPdf(!modalPdf);
	};

	const getDocuments = async ()=>{
		const resp = await api.get(`documents/${doc}`)
		setPdf(resp?.data?.urlPdf)
		setDocument(resp?.data?.urlOts)
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		getDocuments()
	}, []);


	return (
		<>
			<Row className="page-title-importer">
				<Col xs={11} className="page-title-importer-col">
					<Link to={`/importer-documents/${lote}`}>
						<IoIosArrowBack style={{ color: 'black' }} />
					</Link>
					<div className="page-title-importer-text">
						<span>Importer Documents</span>
					</div>
				</Col>
			</Row>
			<div className="importer-page">
				<div className="lot-details-box">
					<Row className="lot-details">
						<Col xs={12} sm={12} md={12} className="lot-details-header">
							<span> {lote}</span>
						</Col>
						<Col xs={12} sm={12} md={12} className="beekeeper-details">
							<div className="disclaimer">
								<span>THIS SITE INFORMATION IS SECURED ON BLOCKCHAIN.</span>
							</div>
						</Col>
						<Col xs={11} sm={11} md={11} className="beekeeper-details">
							<div className="verify-documents-title">
								<span>To verify documents authenticity:</span>
							</div>
						</Col>
						<Col xs={12} sm={12} className="verify-section-box">
							<img src={donwloadIcon} alt="" />
							<div className="line"></div>
							<img src={verifyIcon} alt="" />
						</Col>
						<Col xs={12} sm={12} className="verify-section-link">
							<Row>
								<Col xs={6} sm={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<div className="download-step">
										<span>
											Download document <b>(.pdf)</b> and blockchain proof <b>(.ots)</b> files.
										</span>
									</div>
								</Col>
								<Col xs={6} sm={6} className="verify-button-box">
									<a
										target="_blank"
										rel="noreferrer"
										href="https://opentimestamps.org/?digest=3bc98a0c89639e3e0b717701877a849bb8b38147402dfb19643cb3bd39894535#stamp-and-verify"
									>
										<div className="verify-button">VERIFY</div>
									</a>
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
					<Row className="pdf-container">
						<Col
							xs={6}
							sm={6}
							md={6}
							onClick={(event) => {
								event.preventDefault();
								window.open(pdf);
							}}
						>
							<div className="pdf-box">
								<img src={pdfThumb} alt="" />
							</div>
						</Col>
						<Col xs={6} sm={6} md={6} onClick={() => window.open(document)}>
							<div className="ots-box">
								<img style={{ marginBottom: '10px' }} src={blockChainLock} alt="" />
								<img src={document} alt="" />
							</div>
						</Col>
					</Row>
				</div>
				<Row className="contact-us">
					<a target="_blank" rel="noreferrer" href="https://hunitro.com/contact/">
						CONTACT US
					</a>
				</Row>
				<Row style={{ margin: '1rem' }}>
					<div>
						<img style={{ height: '50px' }} src={logo} alt="" />
					</div>
				</Row>
			</div>
		</>
	);
};
